.fc-list-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}
/* #calendar > div.fc-view-container > div > div > table > tbody > tr.fc-list-item.fc-has-url > td.fc-list-item-title.fc-widget-content {
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
.fc-widget-content > a {
    text-align: left;
}
.fc-list-item-title.fc-widget-content > div {
    text-align: right;
    display: inline;
}
.fc-list-item-marker.fc-widget-content {
    display: none;
}
.fc-list-item-time.fc-widget-content {
    vertical-align: middle;
}
.fc-widget-header {
    display: none;
}
.fc-list-view {
    border: none;
}
.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
    border-color: #212121;
}
.fc-unthemed .fc-list-item:hover td {
    background-color: #6e0000;
}
#calendar > div.fc-view-container .fc-list-empty {
    background-color: #212121;
    padding-bottom: .5rem;
}
@media (min-width: 650px) {
    #calendar {
        font-size: 1.5rem;
    }
}